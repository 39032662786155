































import { Component, Prop, Vue } from 'vue-property-decorator';

import { intervalToDuration, format } from 'date-fns';

import { ReplyComments } from '../interface';

const Arrow = () => import('@/assets/icons/Arrow.vue');

@Component({
  components: {
    Arrow
  }
})
export default class ReplyComment extends Vue {
  @Prop() replies!: ReplyComments[];

  private showReply = false;

  get answer() {
    const quantityReply = this.replies.length;

    return quantityReply > 1 ? `${quantityReply} respostas` : `${quantityReply} resposta`;
  }

  setNickname(reply: ReplyComments) {
    return reply?.nickname || 'Monitor';
  }

  getTime(date: string | Date) {
    const dateWithoutZ = typeof date === 'string' ? date.replace('Z', '') : null;
    const commentDate = new Date(dateWithoutZ || date);
    const currentDate = new Date();

    const dateRange: any = intervalToDuration({
      start: commentDate,
      end: currentDate
    });

    const dateWithin24hours = dateRange && dateRange.days <= 0 && dateRange.months <= 0 && dateRange.years <= 0;

    if (dateWithin24hours) {
      const hour = String(commentDate.getHours()).padStart(2, '0');
      const minutes = String(commentDate.getMinutes()).padStart(2, '0');

      return `${hour}:${minutes}`;
    }

    return `${format(commentDate, 'dd/MM/yyyy')}`;
  }

  handleClickShowReply() {
    this.showReply = !this.showReply;
  }
}
